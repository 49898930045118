<template>
  <div> 

    <!-- ============  StartScreen ================ -->
    <div  
      v-if="currentViewMode === VIEW_MODES.WELCOME"
      @click.prevent="changeViewTo(VIEW_MODES.WHEEL)"
    >
      wilkommen beim GlücksRad ... 
    </div> 

    <!-- ============  START MIT QR-CODE  ================ -->
    <StartWithQR  
      v-if="currentViewMode === VIEW_MODES.STARTQR"
      @click.prevent="showWheelAndStart()"
      @qrScanned="showWheelAndStart()"
      :wheeldata="wheeldata"
    >
    </StartWithQR> 

    <!-- ============  Frage Dialog ================ -->
    <div 
      v-if="currentViewMode === VIEW_MODES.QUIZ"
      class="frageContainer"
    >
      <frage-dialog
        :config="wheeldata"
        :quizData="JSON.parse(getCurrentFeld().frageJsonText)"
        @emitAnswer="waitAfterAnswer" 
      ></frage-dialog>
    </div>

    <!-- ============  Statistix  ================ -->
    <GiftStats
      v-if="showStatisticOverlay === true"
      class="statOverlay"
      @makeNewGift="makeNewGift()"
      :wheeldata="wheeldata"
    ></GiftStats>

    <!-- ============  Nix geht mehr   ================ -->
    <div
      v-if="currentViewMode === VIEW_MODES.NO_PRICES"
      class="ende"
    >
      LEIDER SIND KEINE PREISE MEHR ÜBRIG ...  
    </div>

    <!-- ============  Glücksrad Container  ================ -->
    <div class="wheel_wrap" @mousedown.prevent="spinTheWheel()" @mouseup.prevent="checkToOpenStatWin">
      <!-- background --> 
      <div v-if="wheeldata.bg" class="background" >
        <img class="backgroundimg" :src="wheeldata.bg" />
      </div>

      <div v-else class="background" :style="setBGColor()">
      </div>

      <!-- ,,,, icons Logos und so ... --> 
      <div class="nSBilder" :style="positionNSBilder(index)"
        v-for="(item, index) in  wheeldata.nichtSkalierbareBilder" :key="index">
          <img class="nsImg" :src = item.src>
      </div>

      <div id="animationCont" class="wheel_wrap" 
       v-if="(currentViewMode === VIEW_MODES.PRICE_ANIMATION)
          || (currentViewMode === VIEW_MODES.CLICK_TO_CLOSE_PRICEVIEW)" 
      >
        <!-- my BackGround --> 
        <div 
          v-if="(wheeldata.AnimationView == 'ownglobalbackground')" 
          class=" backgroundimg myBG">
          <img 
            class="" 
            :src="getCurrentFeld().myBG" 
            @click="callNextViewIfWaitForClick()"
          >
        </div>
        
        <!-- Preis Animations --> 
        <div 
          :class="getCurrentFeld().animationType"
          class="awardContainer"
          @click="callNextViewIfWaitForClick()"
        >
          <img class="galleryImg" :src="getCurrentFeld().animationlogo" @animationend="nextView()" >
        </div>
      </div>


      <!-- GlücksRad --> 
      <div class="radContainer" 
        v-if="(currentViewMode === VIEW_MODES.WHEEL) || ((currentViewMode === VIEW_MODES.PRICE_ANIMATION) && (wheeldata.AnimationView == 'likeitis'))" 
      >
        <Wheel
            class="drehscheibe"
            :class="wheelClass"
            :style="styleFromWheelData()"
            ref="wheel"
            :gift="gift"
            @done="waitBeforeDone"
            :config="wheeldata"
        />
      </div>
    </div>
    <!-- ============  ENDE Glücksrad Container  ================ -->

  </div>
</template>

<script>
import Wheel from "@/components/Wheel.vue"
import FrageDialog from "@/components/FrageDialog.vue"
import GiftStats from "@/components/GiftStats.vue"
import StartWithQR from "@/components/StartWithQR.vue"
import {mapGetters} from "vuex";
import { mapActions } from "vuex";

import axios from 'axios';

export default {
  name: 'GluecksRad',

  components: {
    Wheel,
    FrageDialog,
    GiftStats,
    StartWithQR
  },

 props: ['wheeldata'],

  data() {
    return {
      DATA_STRUCTURE_VERSION: 2.0,
      FRONTEND_VERSION: 2.2,
      clicked: false,
      clickCounter: 0,
      gift: 0,
      rotDeg: 0,
      t: 0,
      r: 0,
      animationState: '',
      wheelClass: '',
      showStatisticOverlay: false,
      clicksToOpenStats: 7,
      urlPrintServer: "http://localhost:8101/printerApi/printImage",

      currentViewMode: null,
      VIEW_MODES: {
        STARTQR: "STARTQR",
        WELCOME: "WELCOME",
        WHEEL: "WHEEL",
        PRICE_ANIMATION: "PRICE_ANIMATION",
        CLICK_TO_CLOSE_PRICEVIEW: "CLICK_TO_CLOSE_PRICEVIEW",
        QUIZ: "QUIZ",
        NO_PRICES: "NO_PRICES",
        STATISTICS: "STATISTICS"
      }
    }
  },

  created() {
  },

  async mounted() {
    console.log(this.$options.name + " -> mounted");

    window.addEventListener("keydown", e => {   //e => == function(e)
      console.log(this.$options.name + " -> keydown: " + String.fromCharCode(e.keyCode) + " .. keyCode: " + e.keyCode);
      if (e.keyCode == 116 || e.keyCode == 84) {
        this.checkToOpenStatWin();
      } else {
        this.spinTheWheel();
      }
    });

    this.luckyInit();
    let c = await this.loadGiftStats(this.wheeldata);
    this.verifyData();
    this.restart();
  },

  computed: {
 ...mapGetters([
      "giftStats",
      "nextForcePrice"
    ]),
  },

  methods: {
    ...mapActions([
      "storeGiftStats",
      "loadGiftStats",
      "checkForForcePrice",
      "clearForedPrices",
      "clearLocalStorage"
    ]),

    verifyData: function() {
      const wheelSize = this.wheeldata.felder.length;
      const statSize = this.giftStats.sum.length;

      console.log(" verifyData : wheelSize : " + wheelSize );
      console.log(" verifyData : statSize : " + statSize );
      if (wheelSize != statSize) {
        this.clearLocalStorage();
        this.loadGiftStats(this.wheeldata);
      }
    },
    
    /** set the wheel data 
     *  --- from Json ... 
     * 
     * so make a StopPositionNr. 
     * depending on the selectet modus 
     */
    luckyInit: function () {
      console.log(this.$options.name + " -> luckyInit : ");
      // this.wheeldata = _data;

      if (this.wheeldata.useSound === true) {
        this.startSound = new Audio(this.wheeldata.startsound);
        this.stopSound = new Audio(this.wheeldata.stopsound);
      }
    },

    /** zeigt das Rad an oder auch nicht ... 
     * value = "hidden"  ... (ist so gewachsen ... )
     */
    setWheelCSSClass: function (_value) {
      this.wheelClass = _value;
    },

    isPriceAvailable: function() {
      console.log("this.giftStats: ", this.giftStats);
      // this.wheeldata.felder[i]
      for (let i = 0; i < this.wheeldata.felder.length; i++) {
        if (this.wheeldata.felder[i].limitGiveAways == true) {
          if (this.wheeldata.felder[i].relativeFrequency == 0) {
            // no chance to get ... 
            console.log("Prize " + i + " : relative Frequency == 0")
            continue;
          }
          // limitierter Preis ...
          if (this.giftStats.sum[i] < this.wheeldata.felder[i].absolutePriceCount ) {
            console.log("giftStats.sum[" + i + "] = " + this.giftStats.sum[i] + " < ")
            console.log("absolutePriceCount[" + i + "] = " + this.wheeldata.felder[i].absolutePriceCount + " ?")
            return true;
          } else {
             // max count for prize reached 
            console.log("giftStats.sum[" + i + "] = " + this.giftStats.sum[i] + " >= ")
            console.log("absolutePriceCount[" + i + "] = " + this.wheeldata.felder[i].absolutePriceCount + " ?")
          }
        } else {
          if (this.wheeldata.felder[i].relativeFrequency != 0) {
            console.log("Prize " + i + " : relative Frequency != 0 ?")
            return true;
          }
        }
      }
      // keine Preise mehr übrig 
      console.log("Keine Preise mehr übrig")
      return false;
    },


    makeNewGift: function() {
      // check for NoPrices 
      let priceAvailabe = this.isPriceAvailable();
      console.log("makeNewGift -> isPriceAvailable() " + priceAvailabe)
      if (priceAvailabe === false) {
        this.changeViewTo(this.VIEW_MODES.NO_PRICES);
        return;
      }

      this.checkForForcePrice()

      // es wurde ein preis forciert ... 
      if (this.nextForcePrice && this.nextForcePrice.turnsLeft == 0) {
        console.log(" -> makeNewGift -> nextForcePrice !!! ")
        // set this price 
        this.gift = this.nextForcePrice.id;
        // and clear it 
        this.clearForedPrices();
      } else

      // es gibt die Mäglichkeit, die Warscheinlichkeiten für jedes Feld zu setzten 
      if (this.wheeldata.cheatFrequency == true) {
        let nextGiftNr = -1;
        let tempCounter = 0;
        while (nextGiftNr < 0 ) {
          nextGiftNr = this.getCheatetGiftNr()
          if (this.wheeldata.felder[nextGiftNr].limitGiveAways == true) {
            // nun auf die maximalAnzahl checken 
            if (this.wheeldata.felder[nextGiftNr].absolutePriceCount <= this.giftStats.sum[nextGiftNr]) {
              console.log(" -- makeNewGift -> max count for prize reached : " + nextGiftNr);
              nextGiftNr = -1;
            }
          }
        }
        this.gift = nextGiftNr;
      } else {
        // wir bleiben beim PseudoZufall 
        this.gift = this.randomGift();
      }
      // statistix speichern ... 
      this.storeGiftStats(this.gift);
    },

    positionNSBilder: function (_index) {
      let fromWidthOrHeight = "vh";
      if (this.wheeldata.ausrichtung == "portrait") {
         fromWidthOrHeight = "vw";
      }
      let zLevel = 100;
      if (this.wheeldata.nichtSkalierbareBilder[_index].isInBackground == true) {
        zLevel = -1;
      }
      return { 'left' : this.wheeldata.nichtSkalierbareBilder[_index].posX + "vw",
        'top' : this.wheeldata.nichtSkalierbareBilder[_index].posY + "vh",
        'z-index:' : zLevel,
        'max-width' : this.wheeldata.nichtSkalierbareBilder[_index].size + fromWidthOrHeight,
        'min-width' : this.wheeldata.nichtSkalierbareBilder[_index].size + fromWidthOrHeight};
    },

    setBGColor: function () {
      console.log("setBGColor: " + this.wheeldata.bgColor)
      return { 'background-color' : this.wheeldata.bgColor};
    },

    styleFromWheelData: function () {
      let fromWidthOrHeight = "vh";
      if (this.wheeldata.ausrichtung == "portrait") {
        fromWidthOrHeight = "vw";
      }
      return { 'margin-left' : this.wheeldata.posX + "vw",
        'margin-top' : this.wheeldata.posY + "vh",
        'width' : this.wheeldata.size + fromWidthOrHeight};
    },

    resetValues: function () {
      this.rotDeg = Math.random * 360 + 500;
    },

    /** rad hat aufgehört sich zu drehen 
     */
    waitBeforeDone: function () {
      setTimeout(() => {this.nextView()}, this.wheeldata.waitBeforeDone * 1000);
    },

    // Wird wohl nicht mehr benutzt
    // showAnimation: function () {
    //   //this.awardImg = this.getCurrentFeld().animationlogo
    //   if (this.getCurrentFeld().animationType) {
    //     this.animationState = this.getCurrentFeld().animationType;
    //   } else {
    //     this.animationState = 'awardAnimation';
    //   }
    //   if (this.wheeldata.keepScreenOnAnimation != true) {
    //     // hide the wheel during animation
    //     this.setWheelCSSClass("hidden");
    //   }
    //   console.log("showAnimation -> this.animationState: " + this.animationState);
    // },

    /** jedes Feld ist gleichberechtig und hat die gleichen Warscheinlichkeiten  */
    randomGift: function () {
      const g = Math.floor(Math.random() * this.wheeldata.felder.length);
      console.log("randomGift -> " + g );
      return g;
    },

    callNextViewIfWaitForClick: function() {
      if(this.currentViewMode == this.VIEW_MODES.CLICK_TO_CLOSE_PRICEVIEW ) {
        this.nextView();
      }
    },

    changeViewTo: function(_viewMode) {
      console.log(" changeViewTo : " + _viewMode);
      switch (_viewMode) {
        case this.VIEW_MODES.WELCOME:
          break;
        case this.VIEW_MODES.WHEEL:
          this.makeNewGift();
          this.zeigeWheel();
          break;
      }
      this.currentViewMode = _viewMode;
    },

    nextView: function() {
      console.log('nextView() - begin - .. currentViewMode: ' + this.currentViewMode);
      switch (this.currentViewMode) {
        case this.VIEW_MODES.WELCOME:
          this.changeViewTo(this.VIEW_MODES.WHEEL);
          break;
        case this.VIEW_MODES.WHEEL:
          // animation wenn gewünscht
          if (this.getCurrentFeld().useAnimation) {
            // Animation 
            this.changeViewTo(this.VIEW_MODES.PRICE_ANIMATION);
          } else if (this.getCurrentFeld().useFrageDialog) {
            // QuizFrage 
            this.changeViewTo(this.VIEW_MODES.QUIZ);
          } else {
            // oder von vorne 
            this.changeViewTo(this.VIEW_MODES.WHEEL);
          }  
          break;

        case this.VIEW_MODES.PRICE_ANIMATION:
          //this.printGewinn("Test_Label_Printer.png");
          if (this.getCurrentFeld().printGewinn == true) {
            // printing aktiviert : 
            this.printGewinn(this.getCurrentFeld().animationlogo);
          }


          if (this.getCurrentFeld().requireClickforRestart == true) {
            console.log("nextView() -> requireClickforRestart: " + this.getCurrentFeld().requireClickforRestart);
            this.changeViewTo(this.VIEW_MODES.CLICK_TO_CLOSE_PRICEVIEW);
          }  
          else if(this.getCurrentFeld().useFrageDialog) {
          // QUIZ WENN GEWÜNSCHT 
            this.setWheelCSSClass("hidden");    // ???? CHECK 
            this.changeViewTo(this.VIEW_MODES.QUIZ);
          } else {
            // starte neu
            this.changeViewTo(this.VIEW_MODES.WHEEL);
          }
          break;

        case this.VIEW_MODES.CLICK_TO_CLOSE_PRICEVIEW:
          // QUIZ WENN GEWÜNSCHT 
          if(this.getCurrentFeld().useFrageDialog) {
            this.setWheelCSSClass("hidden");    // ???? CHECK 
            this.changeViewTo(this.VIEW_MODES.QUIZ);
          } else {
            // starte neu
            this.changeViewTo(this.VIEW_MODES.WHEEL);
          }
          break;
        default:
          console.log(`no next VIEW ... `);
      }
      console.log('nextView() - end - .. currentViewMode: ' + this.currentViewMode);
    },

    printGewinn: async function (imageToPrintUri) {
        // let url = "http://localhost:8101/printerApi/printImage"
        imageToPrintUri = "http://wheel-fortune.de" + imageToPrintUri
        let payload = {printImageUrl: imageToPrintUri} //"/printerApi/Test_Label_Printer.png"}
        let requestOptions= { headers: {} }
        console.log(this.$options.name + '-> printGewinn() -> ' + this.urlPrintServer);
        await axios.post(this.urlPrintServer, payload, requestOptions); 
    },

    spinTheWheel: function () {
        this.hideStatistix();
        if (!this.clicked) {
          this.clicked = true;
          this.clickCounter = 0;
          
          console.log("spinTheWheel - classic -> clicked: " + this.clicked);
          console.log("spinTheWheel - classic -> gift: " + this.gift);
          this.$refs.wheel.spin();
        // this.startSound.play()
        } 
        // else {
        //   this.checkToOpenStatWin();
        // }
    },

    checkToOpenStatWin: function() {
          this.clickCounter++;
          console.log(" checkToOpenStatWin() -> clickCounter : " + this.clickCounter);
          if (this.clickCounter >= this.clicksToOpenStats) {
            this.showStatistix();
            this.clickCounter = 0;
          }
    },

    showStatistix: function() {
      this.showStatisticOverlay = true;
    },
    hideStatistix: function() {
      this.showStatisticOverlay = false;
    },

    /** die Warscheinlichkeiten bzw die Häufigkeit für jedes Feld wird im Editor festgelegt und hier Ausgewertet */
    getCheatetGiftNr: function () {
      console.log("- start getCheatetGiftNr -")
      let prozent = 0;
      let value = Math.random() * 100.0;

      for (let i = 0; i < this.wheeldata.felder.length; i++) {
        prozent = prozent + Number(this.wheeldata.felder[i].relativeFrequency);
        if (value < prozent) {
          console.log("- getCheatetGiftNr : " + i + " by value: ", value);
          return i;
        }
      }
      console.error(" FAILURE - NO CHEATED GIFT FOUND ... sind alle Prozente richtig gesezt ??? !!! : " + value);
      return -1;  // Fehler muss abgefangen werden ...  
    },

    getCurrentFeld: function () {
      return this.wheeldata.felder[this.gift];
    },

    /** das LW ist wieder scharf und kann gestartet werden */
    unClick: function () {
      this.clicked = false;
      console.log("unClick -> clicked: " + this.clicked);
    },

    waitAfterAnswer: function () {
      console.log(this.$options.name + ' ->  emitDone ');
      setTimeout(() => {this.nextView}, this.wheeldata.waitBeforeDone * 1000);
    },

    restart: function() {
      if (this.wheeldata.useQR == true ) {
        // start with QR
        this.changeViewTo(this.VIEW_MODES.STARTQR);
      } else 
        if (this.wheeldata.useWelcomeScreen == true ) {
        // start with StartScreen
        this.changeViewTo(this.VIEW_MODES.WELCOME);
      } else
      {
        // or start normal 
        this.changeViewTo(this.VIEW_MODES.WHEEL);
      }
    },

    /** könnte auch restart heissen :) 
     */
    zeigeWheel: function () {
        this.setWheelCSSClass("");
        console.log("zeigeWheel called - > wheelClass: " + this.wheelClass);
        this.animationState = "";
        this.unClick();
    },

    showWheelAndStart: function() {
      this.changeViewTo(this.VIEW_MODES.WHEEL);
      setTimeout(() => {this.spinTheWheel()}, 1000);
    },

    // ..... Animation Functions ........ 
    easeOut: function () {
      let maxR = Math.PI + Math.PI / (this.gift + 1);
      this.t = this.t + 0.005;
      let r = this.easeOutElastic(this.t);
      console.log("easeOut", r);

      if (r != 1 ) {
        setTimeout(() => {
          this.easeOut();
        }, 20);
      }
      this.r = r * maxR;
    },
    easeOutElastic: function (x) {
         return Math.sin((x * Math.PI) / 2);
    }

  },
}
</script>

<style scoped>

  .ende {
    color: darkorange;
    font-size: 10vh;
  }

  .myBG {
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    position: absolute;
  }
  
  .myBG img {
    width: 100%;
  }

  .statOverlay {
    z-index: 2000;
    position: absolute;
    top: 5vh;
    left: 5vw;
    padding: 2%;
    width: 80vw;
    height: 80vh;
    background-color: #cccccc;
    overflow: auto;
  }

  .frageContainer {
    z-index: 2000;
  }

  .radContainer {
    background-color: transparent;
  }

  .rad {
    position: absolute;
    z-index: -10;
    left: 2vw;
    top: 23.5vh;
  }

  .drehscheibe {
    z-index: 20;
  }

  .wheel_wrap {
    display: block;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* cursor: none; */
    user-select: none;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    overflow: hidden;
  }

  .background {
    z-index: -10;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  .backgroundimg {
    max-width: 100vw;
    width: 100vw;
  }

  .hidden {
    display: none;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(0,0);
        opacity: 0;
    }
    12% {
        transform: scale(1.2,1.2);
        opacity: 1;
    }
    24% {
        transform: scale(1,1);
        opacity: 1;
    }
    36% {
        transform: scale(1.2,1.2);
        opacity: 1;
    }
    48% {
        transform: scale(1,1);
        opacity: 1;
    }
    60% {
        transform: scale(1.2,1.2);
        opacity: 1;
    }
    72% {
        transform: scale(1.0,1.0);
        opacity: 1;
    }
    84% {
        transform: scale(1.2,1.2);
        opacity: 1;
    }
    100% {
        transform: scale(0,0);
        opacity: 0;
    }
  }

  .awardContainer {
    z-index: 1000;
    position: absolute;
  }
  .awardContainer img {
    opacity: 0;
  }

  .NO-awardAnimation img{
    animation: zoominoutsinglefeatured 5s ease-in-out;
  }

  .nSBilder {
    position: absolute;
    /* z-index: 500; */
    overflow: hidden;
  }

  .bigLabel {
    font-size: 2vh;
    margin: 0.5vh 0 1vh 0;
  }

  .nsImg {
    width: 100%;
  }
</style>

<template>
  <div id="wheel" :class="['wheel', `wheel_font-size--${config.felder.length}`]" :style="wheelStyle" />
</template>

<script>
  import * as d3 from 'd3'

  export default {
    name: 'Wheel',
    props: ['config', 'gift'],

    emits: ['done'],
    data() {
      return {
        arrow: null,
        // clicked: false,
        container: null,
        pie: null,
        rotation: 0,
        style: {
          width: 2000,
          height: 2000,
        },
        margin: 20,
        svg: null,
        vis: null,

      }
    },
    computed: {
      wheelStyle() {
        return {
          width: `99vw`,
          height: `auto`,
          // width: `${this.wheelSize.width}px`,
          // height: `${this.wheelSize.height}px`,
        }
      },
      wheelSize() {
        const screenWidth = window.outerWidth
        const screenHeight = window.outerHeight
        console.log("Screenwidth= " + screenWidth + " Screenheight= " + screenHeight)
        const width = Math.min(screenWidth, screenHeight, this.style.width) - this.margin
        const height = Math.min(screenWidth, screenHeight, this.style.width) + 120
        return {
          width,
          height,
        }
      },
    },

    mounted() {
      this.rayon = Math.min(this.wheelSize.width, this.wheelSize.height) / 2
      this.createWheel()
    },

    methods: {
      createWheel() {
        // Create Svg
        this.createSvg()
        // Create shadow filter
        this.createDefs()
        // Create Group container
        this.createVis()
        // Declare an arc generator function
        this.createArc()
        // Add the text
        this.addText()
        // Make circle
        this.createMiddleCircle()
        this.createBorderCircle()
        // Add img
        if (this.config.logo) {
          this.addImgOnCenter()
        }
        if(this.config.useRadImg) {
          this.addArrow()
        } else {
          this.createArrow()
        }

        // create arrow
      },
      createSvg() {
        this.svg = d3
          .select('#wheel')
          .append('svg')
          .attr('font-size', this.config.fontsize +'px')
          .attr('height', '100%')
          .attr('width', '100%')
          .attr(
            'viewBox',
            `0 0 ${this.wheelSize.width + this.margin * 2} ${
              this.wheelSize.height
            }`
          )
          .data([this.config.felder])
          .append('g')
          .attr('class', 'wrapper')
          .attr(
            'transform',
            `translate(${(this.wheelSize.width + this.margin * 2) / 2}, ${
              this.wheelSize.height / 2
            })`
          )
      },
      createDefs() {
        const defs = this.svg
          .append('defs')
          .append('filter')
          .attr('id', 'shadow')
          .attr('x', '-100%')
          .attr('y', '-100%')
          .attr('width', '550%')
          .attr('height', '550%')

        defs
          .append('feOffset')
          .attr('in', 'SourceAlpha')
          .attr('dx', 0)
          .attr('dy', 0)
          .attr('result', 'offsetOut')

        defs
          .append('feGaussianBlur')
          .attr('stdDeviation', '9')
          .attr('in', 'offsetOut')
          .attr('result', 'drop')

        defs
          .append('feColorMatrix')
          .attr('in', 'drop')
          .attr('result', 'color-out')
          .attr('type', 'matrix')
          .attr(
            'values',
            `0 0 0 0   0
          0 0 0 0   0
          0 0 0 0   0
          0 0 0 .3 0
        `
          )

        defs
          .append('feBlend')
          .attr('in', 'SourceGraphic')
          .attr('in2', 'color-out')
          .attr('mode', 'normal')
      },
      createVis() {
        this.container = this.svg.append('g').attr('class', 'wheelholder')

        // Create a G on container
        this.vis = this.container.append('g')

        this.pie = d3
          .pie()
          .value(() => {
            return 1
          })
          .padAngle(0.01/6 * this.config.strokeWidthBorder)
          .sort(null)
      },
      createArc() {
        const arc = d3.arc().outerRadius(this.rayon).innerRadius(0)

        // Create the donut slices and also the invisible arcs for the text
        const that = this
        var degreesPerArc = (360 / Object.keys(this.config.felder).length)
        // var pricelogo = this.settings.felder.pricelogo
     
        this.vis
          .selectAll('.middleArcText')
          .data(this.pie(this.config.felder))
          .enter()
          .append('path')
          .attr('class', 'slice')
          .attr('d', arc)
          .attr('stroke', this.config.strokeColorBorder)
          .attr('stroke-width', this.config.strokeWidthBorder)
          .attr('fill', (d) => {
            return d.data.bgColor
          })
          .each(function (d, i) {
            const firstArcSection = /(^.+?)L/

            let newArc = firstArcSection
              .exec(d3.select(this).attr('d'))[1] 
              .replace(/,/g, ' ')

            // if (d.endAngle > (90 * Math.PI) / 180) {
            //   const startLoc = /M(.*?)A/
            //   const middleLoc = /A(.*?)0 0 1/
            //   const endLoc = /0 0 1 (.*?)$/
            //   const newStart = endLoc.exec(newArc)[1]
            //   const newEnd = startLoc.exec(newArc)[1]
            //   const middleSec = middleLoc.exec(newArc)[1]

            //   newArc = `M${newStart}A${middleSec}0 0 0${newEnd}`
            // }


                that.vis
              .append('path')
              
              .attr('class', 'hiddenarcs')
              .attr('id', 'middleArc' + i)
              .attr('d', newArc)
              .style('fill', 'none')

              
              

               that.vis
              .append('svg:image')
              .attr('x', `${-d.data.width/2}%`)
              .attr('y', `${-d.data.abstandr}%`)
              // .attr('y', '-30%')
              .attr('align-content' , 'center')
              .attr('width', `${d.data.width}%`)
              .attr('transform', `rotate(${i*degreesPerArc + degreesPerArc/2})`)
              .attr('xlink:href' , d.data.pricelogo)
            
           
          })
      },
      addText() {
        this.vis
          .selectAll('.middleArcText')
          .data(this.pie(this.config.felder))
          .enter()
          .append('text')
          // .attr('transform', 'rotate(10 198,234)')
          .attr('class', 'middleArcText')
          .attr('dy', (d) => {
            return d.endAngle > (90 * Math.PI) / 180 ? 84 : 84
          })
          .append('textPath')
          .attr('font-family', this.config.font)
          
          
          .attr('startOffset', '50%')
          .attr('text-anchor', 'middle')
          .attr('fill', (d) => {
            return d.data.color
          })
          .attr('xlink:href', (d, i) => {
            return '#middleArc' + i
          })
          .text((d) => {
            return d.data.value
          })
      },
      createMiddleCircle() {
        this.container
          .append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', this.rayon * this.config.middleCircleSize / 100)
          .attr('fill', this.config.middleColor)
          .attr('filter', 'url(#shadow)')
          .attr('stroke-width', this.config.strokeWidthInner)
          .attr('stroke', this.config.strokeColorInner)
      },
      createBorderCircle() {
        this.container
          .append('g')
          .append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', (this.wheelSize.width) / 2)
          .attr('fill', 'transparent')
          .attr('stroke-width', this.config.strokeWidthOuter)
          .attr('filter', 'url(#shadow)')
          .attr('stroke', this.config.strokeColorOuter)
      },
      addImgOnCenter() {
        const { width, height, src } = this.config.logo[0]
          if (this.config.ausrichtung == "portrait") {
            this.container
              .append('svg:image')
              .attr('x', `-${width / 2}vw`)
              .attr('y', `-${width / 2}vw`)
              .attr('width', `${width}vw`)
              .attr('height', `${width}vw`)
              .attr('xlink:href', src)
          }
          else{
            this.container
            .append('svg:image')
            .attr('x', `-${width / 2}vh`)
            .attr('y', `-${width / 2}vh`)
            .attr('width', `${width}vh`)
            .attr('height', `${width}vh`)
            .attr('xlink:href', src)
          }
        
      },

      addArrow() {
       //  const { src } = this.config.pfeil

        this.container
          .append('svg:image')
          .attr('x', `-3%`)
          .attr('y', `-50%`)
          .attr('width', `6%`)
          // .attr('height', `auto`)
          .attr('xlink:href', this.config.pfeil)
      },

      createArrow() {
        const pathArrow =
          'M95.3,9.8c-16.5,0-23.7,15.6-21.9,27c3.4,21.7,21.9,42.2,21.9,42.2s18.5-20.5,21.9-42.2 C118.9,25.4,111.8,9.8,95.3,9.8z'

        this.arrow = this.container
          .append('g')
          .append('path')
          .attr('d', pathArrow)
          .attr('transform', 'translate(-20, -300)')
          .attr('stroke', '#ffffff')
          .attr('fill', '#FFFFFF')
          .attr('filter', 'url(#shadow)')
          .attr(
            'transform',
            `matrix(1, 0, 0, 1, -95, -${
              this.wheelSize.height / 2 - this.margin
            })`
          )
          .attr('stroke-linejoin', 'round')
          .attr('stroke-width', '4')
      },
      // findCurrentSlice(index) {
      //   // return this.config.felder.findIndex((x) => x.id === index) + 1
      //   return this.gift + 1
      // },
      async spin() {
        // if (!this.clicked) {
        //   this.clicked = true

          // Define current gain
          console.log("wheel.spin -> this.gift:  " + this.gift)
          // const slicedGift = this.findCurrentSlice(this.gift)
          // console.log("wheel.spin -> slicedGift:  " + slicedGift)
          const dataLength = this.config.felder.length
          const sliceWidth = 360 / dataLength
          const currentAngle = 360 - sliceWidth * (this.gift)
          // const currentAngle = 360 - sliceWidth * (slicedGift - 1)
          const numberOfRotation = 360 * this.config.numberOfRotations
          const rotation = currentAngle + numberOfRotation

          this.rotation = Math.round(rotation / sliceWidth) * sliceWidth

          let picked = Math.round(
            dataLength - (this.rotation % 360) / sliceWidth
          )
          picked = picked >= dataLength ? picked % dataLength : picked

          // Center slice
          const sliceSize = sliceWidth + sliceWidth / 2
          this.rotation += sliceSize - Math.round(sliceWidth * 2)

          this.interpolate = d3.interpolate(0, this.rotation)

          const animateVis = () => {
            return this.vis
              .transition()
              .duration(this.config.animDuration * 1000)
              .ease(d3.easeBackOut.overshoot(0.3))
              .attrTween('transform', this.animRotation)
              .end()
          }

          await animateVis()

          this.$emit('done', this.config.felder[picked])
        // }
      },
      animRotation() {
        return (t) => {
          return `rotate(${this.interpolate(t)})`
        }
      },
    },
  }
</script>

<style scoped>

  .wheel {
    width: 100%;
    height: auto;
    margin: 0 auto;
    max-height: 100vh;
  }
  .wheel textPath {
    letter-spacing: 1px;
    stroke: rgb(0 0 0 / 10%);
    /* transform: rotate(90 74,34); */
  }
  .wheel_font-size--1,
  .wheel_font-size--2,
  .wheel_font-size--3,
  .wheel_font-size--4 {
    font-size: 16px;
  }
  .wheel_font-size--5,
  .wheel_font-size--6 {
    font-size: 12px;
  }
  .wheel_font-size--7,
  .wheel_font-size--8 {
    font-size: 10px;
  }
</style>

<template>
  <div class="pageContainer " >
    <h1>Statistix</h1>
    <h2>Fast Draws : </h2>
        
    <div @click="simulateForStats(1)" > 1: 1x drehen </div>
    <div @click="simulateForStats(10)"> 2: 10x drehen </div>
    <div @click="simulateForStats(100)"> 3: 100x drehen </div>
         
    
    <h2>last reset (timestamp) : {{ giftStats.resetTime }}</h2>
    <h2>Gesamtzahl der Versuche : {{ giftStats.counter }}</h2>

    <!-- reset local storage -->
    <div> Statisik zurück setzen </div>
    <div class="button" id="resetGiftStatsButton" 
      @click="clickOnReset()" >
        resetGiftStats
    </div>

    <div> 
      <h2> forced Price : </h2>
      <div v-if="nextForcePrice != null">
        {{ nextForcePrice }}
      </div>
      <div v-else>
        No Prices Forced !! 
      </div>
    </div>

    <div>
      <div class="statRow">
        <div> id# </div>
        <div> % </div>
        <div> limit </div>
        <div> ist </div>
        <div> max </div>
        <div> +1 </div>
        <div> win </div>
      </div>
      <div v-for="(item, index) in giftStats.sum" :key="index"
        class="statRow">

        <div>
          {{ index }}
        </div>
        <div>
          {{ wheeldata.felder[index].relativeFrequency }}
        </div>

        <div>
          {{ wheeldata.felder[index].limitGiveAways }}
        </div>
        <div>
          {{ item }}
        </div>
        <div>
          {{ wheeldata.felder[index].absolutePriceCount }}
        </div>

        <div
          class="button" id="index"
          @click="storeGiftStats(index)"
        >
          +1
        </div>
        <div
          class="button" id="index"
          @click="forcePriceNextX(index)"
        >
          win in next 25
        </div>

      </div>

    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: 'GiftStatsOverlay',

  props: ['wheeldata'],
  emits: ['makeNewGift'],

  computed: {
    ...mapGetters([
      "giftStats",
      "nextForcePrice"
    ]),
  },

  mounted() {
    console.log(this.$options.name + " -> mounted");
  },

   methods: {
    ...mapActions([
      "clearLocalStorage",
      "storeGiftStats",
      "forcePriceNextX",
      "loadGiftStats"
    ]),

    simulateForStats: function(_count) {
      for (let i = 0; i < _count; i++) {
         this.$emit('makeNewGift');
      }
    },

    clickOnReset: function() {
      this.clearLocalStorage();
      this.loadGiftStats(this.wheeldata);
    }

   },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.statRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: black;
  border-style: dashed;
}

.statRow div{
  min-width: 5vw;
}

</style>

<template>
  <div class="MainContainer backgroundAnimated">
    <h1> Scan to Spin and Win </h1>

    <!-- background --> 
    <div v-if="wheeldata.QRCode[0].src" class="background" >
      <img class="backgroundimg" :src="wheeldata.QRCode[0].src" />
    </div>

    <!-- QR  --> 
    <a :href="QR_DATA.data" target="_blanc">
      <QRCanvas 
        :options="QR_DATA"
        :style="styleFromConfig"
        class="QRCodeContainer"
        ></QRCanvas>
    </a>

  </div>
</template>

<script >
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

  import { QRCanvas } from 'qrcanvas-vue';
  import axios from 'axios'; 

export default {
  name: "StartWithQR",

  props: ['wheeldata'],
  emits: ['qrScanned'],
  
  components: {
    QRCanvas,
  },

  data() { return {
    debug: true,

      QR_DATA: {
        cellSize: 8,
        data: 'http://weissstreicher.de/mobile/startlocal/',
      },

      checkForStartUrl : 'http://tentable.de:8100/mobileapi/checkforactivation',
      urlForQR_instantServerCall: 'http://tentable.de:8100/mobileapi/startFromQR/',
      urlForQR_instantCall: 'http://tentable.de/mobile/startFromQR',
      urlForQR_buttonCall: 'http://weissstreicher.de/mobile/buttonCall/',
      urlForQR_shakeToCall: 'http://weissstreicher.de/mobile/shakeToCall/',
      myId: "-1",

      startMode_FromLuckyEdit: 'instant', // 'button', 'shake'
    }
  },

  created () {
    console.log(this.$options.name + " -> created -> wheeldata: ", this.wheeldata)
    this.myId = this.makeMyId()
    this.QR_DATA.data = this.getQRlink()
  },

  mounted () {
    this.startCheckWarteliste()
  },
  unmounted () {
    this.stopCheckWarteliste()
  },
  computed:{
    ...mapGetters([
      'axiosHeader',
    ]),

    styleFromConfig () {
      let fromWidthOrHeight = "vh"
      if (this.wheeldata.ausrichtung == "portrait") {
         fromWidthOrHeight = "vw";
       }
      const qr = this.wheeldata.QRCode[0]
      console.log(this.$options.name + " -> styleFromConfig() ->  fromWidthOrHeight: " + fromWidthOrHeight, qr)

      return { "margin-left" : qr.posX + "vw",
        "margin-top" : qr.posY + "vh", 
        "width" : qr.size + fromWidthOrHeight};
    },
  },

  beforeDestroy: function(){
    clearInterval(this.interval);
  },

  methods: {

    ...mapActions([
      'setCurrentView',
      'startLocal',
    ]),

    /** generate a random id - to identify this instance and the QR caller  */
    makeMyId: function () {
      let newId = "1012" + Math.ceil(Math.random() * 100000000);
      return newId;
    },

    /** event is the key */
    getQRlink: function (_id) {
      let retval = this.urlForQR_instantServerCall +  this.myId;

      // if (this.startMode_FromLuckyEdit === 'button') {
      //   retval = urlForQR_buttonCall +  this.myId
      // } else if (this.startMode_FromLuckyEdit === 'shake') {
      //   retval = urlForQR_shakeToCall +  this.myId
      // }
      return retval; 
    },

    processCount: function (countObj) {
      console.log(this.$options.name + ' -> processCount -> recieved: ', countObj);
      if (countObj.retval === true) {
        // QR code scanned - so clear interval and call parent 
        this.stopCheckWarteliste();
        this.$emit('qrScanned');
      }
    },

    getNextPlayer: function () {
      let response = axios.get(this.getNextPlayerUrl, this.axiosHeader)
        .then(response =>  this.startLocal())
        .catch(err => { console.log(err.response); });
    },

    /** start interval check on server */
    startCheckWarteliste: function() {
      this.interval = setInterval(this.checkServerForStart.bind(this), 2000);
    },

    /** stop server call interval */
    stopCheckWarteliste: function() {
      clearInterval(this.interval )
    },

    checkServerForStart: function () {
      console.log(this.$options.name + " -> checkForStart", this.countWaitingsUrl);
      const payload = { id: this.myId };
      let response = axios.post(this.checkForStartUrl, payload, this.axiosHeader)
        .then(response => this.processCount(response.data))
        .catch(err => { console.log(err.response); });
    },

  },
}

</script>

<style scoped>
  .MainContainer {
    width: 100vw;
    height: 100vh;
    display: inline-grid;
  }

@keyframes fadeIt {
  0%   { background-color: #129F3C; }
  25%  { background-color: #AD301B; }
  50% { background-color: #893BC7; }
  75% { background-color: #d3f9c0; }
  100% { background-color: #129F3C; }
}

  .backgroundAnimated{    
    background-image: none !important; 
    animation: fadeIt 400s infinite; 
  }

  .QR {
    margin: 0 auto;
    width: 20vw;
  }

  .background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }
  .backgroundimg {
    width: 100vw;
  }

  .QRCodeContainer {
    z-index: 10;
  }

</style>
